/* eslint-disable array-callback-return */
import { FontWeights, mergeStyleSets, Callout } from "@fluentui/react";
import { complianceResult } from "../objects/complianceResult";
import { renderComplianceTooltip } from "../utils";
import { useBoolean, useId } from "@fluentui/react-hooks";
interface complianceprops {
  complianceResults: Array<complianceResult>;
}

export function ComplianceField(props: complianceprops) {
  const styles = mergeStyleSets({
    button: {
      width: 130,
    },
    callout: {
      width: 320,
      maxWidth: "90%",
      padding: "20px 24px",
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    },
    link: {
      display: "block",
      marginTop: 20,
    },
  });
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  let iconClass: string = "";
  let alertContentText: string = "";
  let backgroundColor = "";
  let isCompliant: boolean = false;
  let isExpired: boolean = false;
  isCompliant = props.complianceResults.every((compResult) => compResult.isCompliant === true);
  isExpired = props.complianceResults.some((compResult) => compResult.isExpired === true);

  if (isCompliant) {
    iconClass = "intelicon-protection-checked-verified-outlined";
    alertContentText = "Compliant";
    backgroundColor = "#008A00";
  } else {
    iconClass = "intelicon-protection-failed-outlined";
    alertContentText = "Not Compliant";
    backgroundColor = "#CE0000";
  }
  if (isExpired) {
    alertContentText = `Expired`;
    backgroundColor = "#FFD100";
  }
  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{ marginTop: "5px", width: "100%", display: "inline-flex" }}
        onClick={toggleIsCalloutVisible}
        onMouseEnter={toggleIsCalloutVisible}
        onMouseOut={toggleIsCalloutVisible}
        id={buttonId}
      >
        <div
          style={{
            backgroundColor: backgroundColor,
            width: "3%",
            height: "100%",
            padding: "3px",
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            color: "black",
            borderWidth: "2px",
            borderColor: "#E9E9E9",
            backgroundColor: "#F9F9F9",

            width: "97%",
          }}
        >
          <div style={{ marginTop: "3px" , marginLeft:"10px"}}>
            <i className={iconClass}></i> {alertContentText}
          </div>
        </div>
      </div>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          <div style={{ margin: 10, padding: 0, overflow: "auto" }}>
            {renderComplianceTooltip(isExpired, isCompliant)}
            <ul style={{ marginTop: 10 }}>
              {props.complianceResults.map((compResult, index) => {
                if (compResult.isCompliant === false) {
                  return (
                    <li key={index}>
                      <span className="badge bg-light text-dark">
                        {compResult.complianceMessage}
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </Callout>
      )}
    </div>
  );
}
