export class GraphUser {
    "@odata.context":  string;
    businessPhones!: any[];
    displayName!: string;
    givenName!: string;
    jobTitle!: string;
    mail!: string;
    mobilePhone!: null;
    officeLocation!: null;
    preferredLanguage!: null;
    surname!: string;
    userPrincipalName!: string;
    id!: string;
    loaded: boolean = false;
}