export class M365GroupActivity {
    exchangeMailboxStorageUsedByte?: string = "";
    exchangeMailboxTotalItemCount?: string = "";
    exchangeReceivedEmailCount?: string = "";
    externalMemberCount?: string = "";
    groupId?: string = "";
    groupName?: string = "";
    groupType?: string = "";
    isDeleted?: string = ""
    lastActivityDate?: string = ""
    memberCount?: string = "";
    ownerPrincipalName?: string = "";
    reportPeriod?: string = "";
    reportRefreshDate?: string = "";
    sharePointActiveFileCount?: string = "";
    sharePointSiteStorageByte?: string = "";
    sharePointTotalFileCount?: string = "";
    yammerLikedMessageCount?: string = "";
    yammerPostedMessage?: string = "";
    yammerReadMessageCount?: string = "";
    lastActivityDateFormatted?: Date;
}