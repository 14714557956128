import { left } from "@popperjs/core";
import { resource } from "../objects/resource";
import { DirectionalHint, ITooltipHostStyles, TooltipDelay, TooltipHost } from "@fluentui/react";

interface M365CardProps {
  ownedResource: resource;
}

/**
 *
 * @param props
 * @returns
 */
export function M365Card(props: M365CardProps) {
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" ,width:"100%"} };
  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Display Name:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.displayName}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Type:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={
                props.ownedResource.resourceType === "CSPO"
                  ? "SPO"
                  : props.ownedResource.resourceType
              }
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Last Activity Date:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.lastUpdatedFormatted.toDateString()}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Sensitivity Label:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={
                props.ownedResource.sensitivityLabel === ""
                  ? "Not Set"
                  : props.ownedResource.sensitivityLabel
              }
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              M365 Connected:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.isGroupConnected ? "Yes" : "No"}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Privacy:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.privacy}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Mailbox Status:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.mailboxEnabled ? "Enabled" : "Disabled"}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Mailbox Address:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={
                props.ownedResource.mailboxEnabled ? props.ownedResource.mailbox : "Not Enabled"
              }
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              AGS Managed:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.agsTracked ? "Tracked" : "Not Tracked"}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Owner Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.owners.length}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Member Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.membersCount}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              External Members Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.externalMembersCount}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              ObjectID:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.azureAdId}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Last Renewed:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <TooltipHost
              content={
                props.ownedResource.renewActivityLog?.actingUser === "Not Renewed"
                  ? "This resource has not been renewed yet"
                  : `Renewed on ${props.ownedResource.renewActivityLog?.activityDateTime}`
              }
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}
            >
              <input
                style={{ display: "inline-block" }}
                type="text"
                className="form-control"
                value={props.ownedResource.renewActivityLog?.actingUser}
                disabled
              />
            </TooltipHost>
          </div>
        </div>
      </div>
    </div>
  );
}

export default M365Card;
