/* eslint-disable array-callback-return */
import { FontWeights, mergeStyleSets, Callout } from "@fluentui/react";
import { complianceResult } from "../objects/complianceResult";
import { isWithinLast90Days } from "../utils";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { ComplianceRule } from "../enums";
interface expFieldProps {
  complianceResults: Array<complianceResult>;
  formatedComplianceDate: string;
  lastActivityDate: Date;
  sensitivityLabel: string;
}

export function ExpirationDateField(props: expFieldProps) {
  const styles = mergeStyleSets({
    button: {
      width: 130,
    },
    callout: {
      width: 320,
      maxWidth: "90%",
      padding: "20px 24px",
    },
    title: {
      marginBottom: 12,
      fontWeight: FontWeights.semilight,
    },
    link: {
      display: "block",
      marginTop: 20,
    },
  });
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  let tooltipText: string = "";
  //Default Style: Success
  let backgroundColor: string = "#008A00";
  let iconClass: string = "intelicon-protection-checked-verified-outlined";
  let formatedComplianceDate = props.formatedComplianceDate;
  let isTwoOwnerCompliant = false;

  (props.complianceResults as Array<complianceResult>).forEach((compResult) => {
    if (compResult.complianceRule === ComplianceRule.TwoOwnerEnforcement) {
      isTwoOwnerCompliant = compResult.isCompliant;
    }
  });

  (props.complianceResults as Array<complianceResult>).forEach((compResult) => {
    if (compResult.complianceRule === ComplianceRule.LeaseExpiration) {
      let dateDifference: number = compResult.remainingLeaseDays;
      tooltipText = compResult.complianceMessage;
      if (
        isWithinLast90Days(props.lastActivityDate) &&
        dateDifference > 0 &&
        isTwoOwnerCompliant &&
        props.sensitivityLabel !== "Internal - External"
      ) {
        backgroundColor = "#008A00";
        iconClass = "intelicon-protection-checked-verified-outlined";
        tooltipText =
          "Not set: This workspace has been active for the last 3 months, therefore the lease expiration is not set";
        formatedComplianceDate = "Not Set";
      } else if (dateDifference < 0) {
        //If already expired use red color (error)
        backgroundColor = "#CE0000";
        iconClass = "intelicon-protection-failed-outlined";
      } else if (dateDifference > 0 && dateDifference <= 7) {
        //If remaining lease days from 0 - 7 use red color (error)
        backgroundColor = "#CE0000";
        iconClass = "intelicon-protection-failed-outlined";
      } else if (dateDifference > 7 && dateDifference <= 90) {
        //If remaining lease days from 8 - 89 use orange color (warning)
        backgroundColor = "#FFD100";
        iconClass = "intelicon-protection-failed-outlined";
      } else if (dateDifference > 90) {
        //If remaining lease days 90+ use green color (success)
        backgroundColor = "#008A00";
        iconClass = "intelicon-protection-checked-verified-outlined";
      }
    }
  });
  return (
    <div style={{ textAlign: "left" }}>
      <a
        href="https://intel.sharepoint.com/sites/O365Help/SitePages/Workspace-Management-Tool.aspx#how-do-i-to-remediate-compliance-state"
        target="_blank"
        rel="noreferrer"
      >
        <div
          style={{ marginTop: "5px", width: "100%", display: "inline-flex" }}
          onMouseEnter={toggleIsCalloutVisible}
          onMouseOut={toggleIsCalloutVisible}
          id={buttonId}
        >
          <div
            style={{
              backgroundColor: backgroundColor,
              width: "3%",
              height: "100%",
              padding: "3px",
            }}
          >
            &nbsp;
          </div>
          <div
            style={{
              color: "black",
              borderWidth: "2px",
              borderColor: "#E9E9E9",
              backgroundColor: "#F9F9F9",

              width: "97%",
            }}
          >
            <div style={{ marginTop: "3px" , marginLeft:"10px"}}>
              <i className={iconClass}></i> {formatedComplianceDate}
            </div>
          </div>
        </div>
      </a>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus
        >
          {tooltipText} <a href="*">Click for more information</a>
        </Callout>
      )}
    </div>
  );
}
