export class SharePointActivity {
    activeFileCount?: string = "";
    fileCount?: string = "";
    lastActivityDate?: string = "";
    lastActivityDateFormatted?: Date;
    pageViewCount?: string  = "";
    siteId?: string = "";
    spourl?: string = "";
    storageAllocatedByte?: string = "";
    storageUsedByte?: string = "";
    template?: string = "";
    visitedPageCount?: string = "";
}