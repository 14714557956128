export class YammerActivity {
    groupDisplayName?: string;
    isDeleted?: string;
    ownerPrincipalName?: string;
    lastActivityDate?: string;
    lastActivityDateFormatted?: Date;
    groupType?: string;
    office365Connected?: string;
    memberCount?: string;
    postedCount?: string;
    readCount?: string;
    likedCount?: string;
}