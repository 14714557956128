import React, { useEffect, useRef, useState } from "react";
import { ShimmerElementType, Shimmer, createTheme } from "@fluentui/react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ResourceList from "./pages/resources-page";
import ErrorPage from "./pages/error-page";
import MainNavbar from "./components/navbar";
import { useMsal } from "@azure/msal-react";
import { apiScopes } from "./authConfig";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { getUserProfileInformation } from "./services/graph";
import { GraphUser } from "./objects/graph-user";
import { Providers, SimpleProvider, ProviderState } from "@microsoft/mgt-element";
import { ThemeProvider } from "react-bootstrap";
import "@pnp/sp/webs";
import Swal from "sweetalert2";
import { errorsTypes } from "./errorTypes";
import { activityLog } from "./objects/activityLog";
import { getCurrentUserActivity } from "./services/resource-manager";
const router = createBrowserRouter([
  {
    path: "/",
    element: <ResourceList />,
    errorElement: <ErrorPage />,
  },
]);
declare global {
  interface Window {
    CURRENT_USER_EMAIL: string;
  }
}
/**
 * Root application component
 */
export const App: React.FunctionComponent = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [currentGraphUser, setCurrentGraphUser] = useState(new GraphUser());
  const [apiData, setApiData] = useState(null);
  const [initComplete, setinitComplete] = useState(false);
  const didLoadData = useRef(false);
  const [userActivityList,setUserActivityList] = useState<Array<activityLog>>([]);

  const appTheme = createTheme({
    defaultFontStyle: {
      fontFamily:
        "IntelOne Display, IntelOne BodyText, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    },
  });

  const getCustomElements = (): JSX.Element => {
    let shimmerRowsList = [];
    for (let index = 0; index < 10; index++) {
      shimmerRowsList.push(
        <Shimmer key={index}
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 30 },
            { type: ShimmerElementType.gap, width: "1%", height: 30 },
            { type: ShimmerElementType.line, height: 30 },
          ]}
        ></Shimmer>
      );
    }

    return <div>{shimmerRowsList}</div>;
  };
  const intervalTime = 100 * 60 * 1000;
  function startMsalSessionAndKeepAlive() {
    const accessTokenRequest = {
      scopes: apiScopes.graphScopes,
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        //Once the token is retrieved, let graph tookit know that the login process is complete
        Providers.globalProvider = new SimpleProvider((scopes: string[]) => {
          return new Promise((resolve) => {
            resolve(accessToken);
          });
        });
        Providers.globalProvider.setState(ProviderState.SignedIn);
        //Start making calls to get user profile information and resource information from graph and pnp
        getUserProfileInformation(accessToken).then((response) => {
          //authenticatePnp();
          setCurrentGraphUser(response);
          window.CURRENT_USER_EMAIL = (response as GraphUser).mail;
          didLoadData.current = true;
          setinitComplete(true);
          setApiData(response);
        });
      })
      .catch((error) => {
        Swal.fire({
          titleText: errorsTypes.sessionExpired.errorTitle,
          text: errorsTypes.sessionExpired.errorSubtitle,
          icon: "error"
        });
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
      });
  }
  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        scopes: apiScopes.graphScopes,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          //Once the token is retrieved, let graph tookit know that the login process is complete
          Providers.globalProvider = new SimpleProvider((scopes: string[]) => {
            return new Promise((resolve) => {
              resolve(accessToken);
            });
          });
          Providers.globalProvider.setState(ProviderState.SignedIn);
          //Start making calls to get user profile information and resource information from graph and pnp
          getUserProfileInformation(accessToken).then((response) => {
            //authenticatePnp();
            setCurrentGraphUser(response);

            //Try to get current user activity
            try{
              const resourceManagementAPITokenRequest = {
                scopes: apiScopes.resourceManagementScopes,
                account: accounts[0],
              };
              instance
              .acquireTokenSilent(resourceManagementAPITokenRequest)
              .then((resourceManagementAPITokenResponse) => {
                getCurrentUserActivity(resourceManagementAPITokenResponse.accessToken)
                .then(function (userActivityList) {
                  let listItemCount: number = (userActivityList.length - 1);
                  userActivityList.forEach(element => {
                    element.id = listItemCount;
                    listItemCount = listItemCount - 1;
                  });
                  setUserActivityList(userActivityList);            
                })
                .catch(function (errorMsg: any) {
                  console.log(errorMsg);
                  Swal.fire(
                    errorsTypes.somethingWentWrong.errorTitle,
                    errorsTypes.somethingWentWrong.errorSubtitle,
                    "error"
                  );
                });
              });
            }
            catch{
              console.log("Error while trying to retrieve user activity");
            }
            window.CURRENT_USER_EMAIL = (response as GraphUser).mail;
            didLoadData.current = true;
            setinitComplete(true);
            setApiData(response);
          });
        })
        .catch((error) => {
          Swal.fire({
            titleText: errorsTypes.sessionExpired.errorTitle,
            text: errorsTypes.sessionExpired.errorSubtitle,
            icon: "error"
          });
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [instance, accounts, inProgress, apiData, userActivityList]);
  setInterval(startMsalSessionAndKeepAlive, intervalTime);
  return (
    <ThemeProvider theme={appTheme}>
      <div>
        <MainNavbar userName={currentGraphUser.displayName} userActivity={userActivityList}/>
        {/*<Person personQuery="me" />*/}
        <div style={{ display: !initComplete ? "block" : "none" }}>
          <br></br>
          <div className="card" style={{ margin: "15px" }}>
            <div className="card-body">{getCustomElements()}</div>
          </div>
        </div>
        <div className="routerContainer" style={{ display: initComplete ? "block" : "none" }}>
          <RouterProvider router={router} />
        </div>
      </div>
    </ThemeProvider>
  );
};
