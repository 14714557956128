import { left } from "@popperjs/core";
import { resource } from "../objects/resource";
import { DirectionalHint, ITooltipHostStyles, TooltipDelay, TooltipHost } from "@fluentui/react";

interface SPOSiteCardProps {
  ownedResource: resource;
}

export function SPOSiteCard(props: SPOSiteCardProps) {
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" ,width:"100%"} };
  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Site URL:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.siteUrl}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Display Name:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={
                props.ownedResource.displayName !== ""
                  ? props.ownedResource.displayName
                  : props.ownedResource.siteUrl
              }
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Last Activity:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.lastUpdatedFormatted.toDateString()}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Site ID:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.siteId}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              M365 Connected:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value="Not Connected"
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Sensitivity Label:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              value={props.ownedResource.sensitivityLabel === "" ? "Not Set" : props.ownedResource.sensitivityLabel}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Owner Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              id="inputPassword"
              value={props.ownedResource.owners.length}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Member Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              id="inputPassword"
              value={props.ownedResource.externalMembersCount}
              disabled
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              External Member Count:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <input
              style={{ display: "inline-block" }}
              type="text"
              className="form-control"
              id="inputPassword"
              value={props.ownedResource.externalMembersCount}
              disabled
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Last Renewed:
            </label>
          </div>
          <div className="col-4 col-lg-5">
          <TooltipHost
              content={
                props.ownedResource.renewActivityLog?.actingUser === "Not Renewed"
                  ? "This resource has not been renewed yet"
                  : `Renewed on ${props.ownedResource.renewActivityLog?.activityDateTime}`
              }
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}
            >
              <input
                style={{ display: "inline-block" }}
                type="text"
                className="form-control"
                value={props.ownedResource.renewActivityLog?.actingUser}
                disabled
              />
            </TooltipHost>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Site Type:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <textarea
              style={{ display: "inline-block" }}
              className="form-control"
              id="inputPassword"
              value={props.ownedResource.siteTemplate.title}
              disabled
              rows={3}
            />
          </div>
          <div className="col-2 col-lg-1">
            <label
              style={{
                display: "inline-block",
                fontWeight: "bold",
                float: left,
                inlineSize: "100%",
                textAlign: "start",
                paddingLeft: "4%",
              }}
              className="col-sm-2 col-form-label"
            >
              Site Description:
            </label>
          </div>
          <div className="col-4 col-lg-5">
            <textarea
              style={{ display: "inline-block" }}
              className="form-control"
              id="inputPassword"
              value={props.ownedResource.siteTemplate.description}
              disabled
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SPOSiteCard;
