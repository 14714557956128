//Change redirectUri to https://m365management.intel.com if you are going to deploy to prod
export const msalConfig = {
  auth: {
    clientId: "d230b787-4cd1-4c28-af82-e302788a9900",
    authority: "https://login.microsoftonline.com/46c98d88-e344-4ed4-8496-4ed7712e255d", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://m365management.intel.com",
    tenantID: "46c98d88-e344-4ed4-8496-4ed7712e255d"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const apiScopes = {
    graphScopes: ["User.Read","User.Read.All","Group.ReadWrite.All","Directory.Read.All"],
    resourceManagementScopes: ["api://830060af-84cf-4277-ab53-ab6f234828d8/Resources.ReadWrite"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphOwnedGroupsEndpoint: "https://graph.microsoft.com/v1.0/me/ownedObjects/microsoft.graph.group"
};

//Use https://cse-appservice-int.azurewebsites.net/api when deploying for
export const resourceManagementAPIConfig = {
  baseURL: "https://cse-appservice-prod.azurewebsites.net/api",
  baseActivityURL: "https://cse-appservice-prod.azurewebsites.net/activity",
  getM365Spaces: "/resources/m365groups/owned",
  getSPOSpaces: "/resources/sposites/owned",
  getTeam: "/resources/m365groups/teams/",
  getMyActivity: "/myactivity"

}
export const sharePointPnpConfig = {
  baseUrl: "https://intel.sharepoint.com",
  pnpDefaultScope: "https://intel.sharepoint.com/.default"
}

export const m365AppsBaseLinks = {
  sharepoint: "https://intel.sharepoint.com",
  yammer:"https://web.yammer.com/main/org/intel.onmicrosoft.com"
}

export const otherValues = {
  agsAtributeName : "extension_0f4113104c90419c9f4205e0384e2ac7_IntelCloudFlag"
}
