import { graphConfig } from "../authConfig";

/**
 * Gets the current user profile, includes display name and email
 */
export async function getUserProfileInformation(accessToken: string): Promise<any> {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

/**
 * Gets all m365 group resources owned by the current user
 */
export async function getOwnedM365Resources(accessToken: string): Promise<any> {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphOwnedGroupsEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}


