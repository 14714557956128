import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ITooltipHostStyles,
  Link,
  PrimaryButton,
  TooltipHost,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  PeoplePicker,
  Person,
  PersonCardInteraction,
  PersonType,
  UserType,
  ViewType,
  MgtPerson 
} from "@microsoft/mgt-react";
import { left } from "@popperjs/core";
import React from "react";
import { ChangeType, ComplianceRule, SensitivityLabel } from "../enums";
import { directoryObject } from "../objects/directoryObj";
import { resource } from "../objects/resource";
import {
  ConvertBytesToMB,
  ConvertDateStringToLongDate,
  shouldDisableLaseExtend,
} from "../utils";
import { ComplianceField } from "./compliance-field";
import M365Card from "./m365-card";
import SPOSiteCard from "./spo-site-card";
import Swal from "sweetalert2";
import {
  addM365GroupOwner,
  extendResourceLease,
  updateSensitivityLabel,
} from "../services/resource-manager";
import { useMsal } from "@azure/msal-react";
import { apiScopes } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { errorsTypes } from "../errorTypes";
import StatusCode from "status-code-enum";
import { toast } from "react-toastify";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  keepInBounds: true,
};
interface resourceDetailProps {
  ownedResource: resource;
  baseTabId: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}
/**
 * Represents the details of a single M365 or SPO resources, displayed by the resources grid
 */
export function ResourceDetailM365(props: resourceDetailProps) {
  MgtPerson.config.useContactApis = true;
  const [hideOwnerSelectionDialog, { toggle: toggleOwnerSelectionHideDialog }] = useBoolean(true);
  const [hideAGSDialog, { toggle: toggleAGSHideDialog }] = useBoolean(true);
  const [ownerIsSelected, { toggle: toggleOwnerIsSelected }] = useBoolean(true);
  const [isDraggable] = useBoolean(false);
  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");
  const { instance, accounts } = useMsal();
  const calloutProps = { gapSpace: 0 };
  // The TooltipHost root uses display: inline by default.
  // If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block", overflow: "auto" },
  };
  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };
  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId]
  );
  const twoOwnerDialogContentProps = {
    type: DialogType.normal,
    title: "Add Back up Owner",
    closeButtonAriaLabel: "Close",
    subText: "You can pickup the missing owner for your collaboration space here:",
  };
  const AGSDialogContentProps = {
    type: DialogType.normal,
    title: "Add Back up Owner",
    closeButtonAriaLabel: "Close",
    subText: "Access to this workspace is managed through Access Management System (AGS). Go to AGS to add back up owners.",
  };
  const navigateToAGS = () =>{
    window.open("https://intel.sharepoint.com/sites/AGS_Help/SitePages/Getting-Started.aspx", "_blank")?.focus();
    toggleAGSHideDialog();
  };
  let selectedOwners: Array<directoryObject> = [];
  let twoOwnerEnformentCompliant: boolean = true;
  // This variable is planned to be used in a future change
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let resourceIsActive: boolean = true;
  let remainingDays: number = 0;
  function calculateCompliance() {
    for (let index = 0; index < props.ownedResource.compliance.length; index++) {
      if (
        props.ownedResource.compliance[index].complianceRule ===
          ComplianceRule.TwoOwnerEnforcement &&
        !props.ownedResource.compliance[index].isCompliant
      ) {
        twoOwnerEnformentCompliant = false;
      }
      if (
        props.ownedResource.compliance[index].complianceRule === ComplianceRule.LeaseExpiration &&
        props.ownedResource.compliance[index].isExpired
      ) {
        resourceIsActive = false;
      }
      if (props.ownedResource.compliance[index].complianceRule === ComplianceRule.LeaseExpiration) {
        remainingDays = props.ownedResource.compliance[index].remainingLeaseDays;
      }
    }
  }
  calculateCompliance();
  async function validateExternalResourceLease(): Promise<void> {
    Swal.fire({
      title: "Extending lease...",
      didOpen: () => {
        Swal.showLoading();
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            if (
              props.ownedResource.sensitivityLabel !== (SensitivityLabel.InternalOnly as string)
            ) {
              Swal.fire({
                title: "External Facing Collaboration Space",
                text: "This collaboration space is external facing, that allows you to collaborate with people outside the intel network. After renewing the lease over this space do you still need it to be external facing?",
                showDenyButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "Yes, keep the space as external",
                denyButtonText: "No, convert to internal",
                cancelButtonText: "Cancel, I'll renew later",
              }).then(async (result) => {
                const toastLeaseRenewId = toast.loading("Updating resource lease");
                /* Read more about isConfirmed, isDenied below */
                if (result.isDenied) {
                  let sensitivityLabelChgResult = await updateSensitivityLabel(
                    accessTokenResponse.accessToken,
                    props.ownedResource.azureAdId,
                    true,
                    SensitivityLabel.InternalOnly
                  );
                  let operationResult = await extendResourceLease(
                    accessTokenResponse.accessToken,
                    props.ownedResource.azureAdId,
                    true
                  );
                  if (
                    operationResult === StatusCode.SuccessOK &&
                    sensitivityLabelChgResult === StatusCode.SuccessOK
                  ) {
                    props.refreshDatasetFunction(
                      props.ownedResource.azureAdId,
                      ChangeType.UpdateLeaseInternal,
                      null
                    );
                    calculateCompliance();
                    let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                    try {
                      let buttonElement = document.getElementById(collapseButtonId);
                      buttonElement?.click();
                    } catch {
                      console.log("Could not trigger tab collapse");
                    }
                    toast.update(toastLeaseRenewId, {
                      render: "Operation Complete",
                      type: "success",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  } else {
                    toast.update(toastLeaseRenewId, {
                      render: errorsTypes.internalServerError.errorSubtitle,
                      type: "error",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  }
                } else if (result.isConfirmed) {
                  let operationResult = await extendResourceLease(
                    accessTokenResponse.accessToken,
                    props.ownedResource.azureAdId,
                    true
                  );
                  if (operationResult === StatusCode.SuccessOK) {
                    props.refreshDatasetFunction(
                      props.ownedResource.azureAdId,
                      ChangeType.UpdateLease,
                      null
                    );
                    calculateCompliance();
                    let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                    try {
                      let buttonElement = document.getElementById(collapseButtonId);
                      buttonElement?.click();
                    } catch {
                      console.log("Could not trigger tab collapse");
                    }
                    toast.update(toastLeaseRenewId, {
                      render: "Operation Complete",
                      type: "success",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  } else {
                    toast.update(toastLeaseRenewId, {
                      render: errorsTypes.internalServerError.errorSubtitle,
                      type: "error",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  }
                } else if (result.isDismissed) {
                  toast.update(toastLeaseRenewId, {
                    render: "Operation Cancelled",
                    type: "info",
                    isLoading: false,
                    autoClose: 5000,
                  });
                  Swal.close();
                }
              });
            } else {
              Swal.fire({
                title: "Extend lease for this resource",
                text: "Do you want to extend the lease on this resource for 6 more months?",
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "Extend for 6 months",
                cancelButtonText: "Cancel",
              }).then(async (result) => {
                const toastLeaseRenewId = toast.loading("Updating resource lease");
                if (result.isConfirmed) {
                  let operationResult = await extendResourceLease(
                    accessTokenResponse.accessToken,
                    props.ownedResource.azureAdId,
                    true
                  );
                  Swal.hideLoading();
                  Swal.close();
                  if (operationResult === StatusCode.SuccessOK) {
                    props.refreshDatasetFunction(
                      props.ownedResource.azureAdId,
                      ChangeType.UpdateLease,
                      null
                    );
                    let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                    try {
                      let buttonElement = document.getElementById(collapseButtonId);
                      buttonElement?.click();
                    } catch {
                      console.log("Could not trigger tab collapse");
                    }
                    toast.update(toastLeaseRenewId, {
                      render: "Operation Complete",
                      type: "success",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  } else {
                    toast.update(toastLeaseRenewId, {
                      render: errorsTypes.internalServerError.errorSubtitle,
                      type: "error",
                      isLoading: false,
                      autoClose: 8000,
                    });
                  }
                } else if (result.isDismissed) {
                  toast.update(toastLeaseRenewId, {
                    render: "Operation Cancelled",
                    type: "info",
                    isLoading: false,
                    autoClose: 5000,
                  });
                }
              });
            }
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
                type: "error",
                isLoading: false,
                autoClose: 8000,
              });
            }
            console.log(error);
          });
      },
    });
  }

  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <Dialog
        hidden={hideOwnerSelectionDialog}
        onDismiss={toggleOwnerSelectionHideDialog}
        dialogContentProps={twoOwnerDialogContentProps}
        modalProps={modalProps}
      >
        <div className="row">
          <div className="col">
            <div>
              <PeoplePicker
                type={PersonType.person}
                userType={UserType.user}
                className="mgt-light light-border"
                userFilters={`userType eq 'Member' and mail ne '${
                  (window as any).CURRENT_USER_EMAIL
                }'`}
                showMax={5}
                selectionMode="single"
                selectionChanged={(event: any) => {
                  selectedOwners = [];
                  let selectedValue: Array<directoryObject> = event[
                    "detail"
                  ] as Array<directoryObject>;
                  if (selectedValue.length > 0) {
                    selectedOwners = [selectedValue[0]];
                  } else {
                    toggleOwnerIsSelected();
                  }
                }}
              ></PeoplePicker>
              <div
                className="invalid-feedback"
                style={{ display: ownerIsSelected ? "none" : "grid" }}
              >
                Please choose a new owner
              </div>
            </div>
          </div>
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              if (selectedOwners.length > 0) {
                toggleOwnerSelectionHideDialog();
                Swal.showLoading();
                instance
                  .acquireTokenSilent(accessTokenRequest)
                  .then(async (accessTokenResponse) => {
                    let operationResult = await addM365GroupOwner(
                      accessTokenResponse.accessToken,
                      props.ownedResource.azureAdId,
                      selectedOwners[0].id!
                    );
                    const toastLeaseRenewId = toast.loading("Adding Group Owner");
                    if (operationResult === StatusCode.SuccessOK) {
                      props.refreshDatasetFunction(
                        props.ownedResource.azureAdId,
                        ChangeType.AddOwner,
                        selectedOwners[0].userPrincipalName!
                      );
                      let collapseButtonId = props.baseTabId.replace("tab-", "btn-expandResource");
                      try {
                        let buttonElement = document.getElementById(collapseButtonId);
                        buttonElement?.click();
                      } catch {
                        console.log("Could not trigger tab collapse");
                      }
                      toast.update(toastLeaseRenewId, {
                        render: "Operation Complete",
                        type: "success",
                        isLoading: false,
                        autoClose: 8000,
                      });
                    } else {
                      toast.error(errorsTypes.somethingWentWrong.errorSubtitle, {
                        type: "error",
                        isLoading: false,
                        autoClose: 8000,
                      });
                    }
                  })
                  .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                      Swal.fire({
                        titleText: errorsTypes.sessionExpired.errorTitle,
                        text: errorsTypes.sessionExpired.errorSubtitle,
                        icon: "error",
                        willClose: () => {
                          instance.acquireTokenRedirect(accessTokenRequest);
                        },
                      });
                    }
                    console.log(error);
                  });
              }
            }}
            text="Confirm"
          />
          <DefaultButton onClick={toggleOwnerSelectionHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideAGSDialog}
        onDismiss={toggleAGSHideDialog}
        dialogContentProps={AGSDialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={navigateToAGS} text="Go to AGS" />
          <DefaultButton onClick={toggleAGSHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>

      <div className="row">
        <ul className="nav nav-tabs">
          <li className="nav-item" role="presentation" style={{ borderColor: "#E9E9E9" }}>
            <button
              type="button"
              id={props.baseTabId + "-overview"}
              className="nav-link active nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-overview-content"}
              role="tab"
              aria-controls="home"
              aria-selected="true"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-contacts-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Overview
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-owners"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-owners-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-primary-user-person-solid" style={{ fontWeight: "bold" }}></i>{" "}
              Owners
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-activity"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-activity-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i className="intelicon-activity-outlined" style={{ fontWeight: "bold" }}></i>{" "}
              Activity
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              type="button"
              id={props.baseTabId + "-compliance"}
              className="nav-link nav-link-resource"
              data-bs-toggle="tab"
              data-bs-target={"#" + props.baseTabId + "-compliance-content"}
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              style={{ fontWeight: "bold" }}
            >
              <i
                className="intelicon-protection-checked-verified-solid"
                style={{ fontWeight: "bold" }}
              ></i>{" "}
              Compliance
            </button>
          </li>
        </ul>
        <div
          className="tab-content border-end border-bottom border-start"
          style={{ backgroundColor: "#E9E9E9" }}
        >
          <div
            className="tab-pane fade active show "
            id={props.baseTabId + "-overview-content"}
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {props.ownedResource.isGroupConnected ? (
              <M365Card ownedResource={props.ownedResource} />
            ) : (
              <SPOSiteCard ownedResource={props.ownedResource} />
            )}

            <br />
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-owners-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div style={{ paddingLeft: "14px" }}>
              <br />
              <div className="row">
                <div className="col">
                  <br />
                  <div style={{ paddingLeft: "24px", paddingRight: "14px" }}>
                    <table className="table" style={{ backgroundColor: "white" }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>User Type</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.ownedResource.owners.map((owner: string, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "40%" }}>
                                {owner.startsWith("ad_") ? (
                                  <Person
                                  fallbackDetails={{displayName:owner.trim(),initials:"AD"}}
                                  personCardInteraction={PersonCardInteraction.none}
                                  avatarType="photo"
                                  avatarSize="small"
                                  view={ViewType.oneline}
                                  
                                ></Person> 
                                ) : (
                                  <Person
                                    fetchImage
                                    personQuery={owner.trim()}
                                    person-card="hover"
                                    showPresence={true}
                                    personCardInteraction={PersonCardInteraction.hover}
                                    avatarType="photo"
                                    avatarSize="small"
                                    view={ViewType.oneline}
                                  ></Person> 
                                )}
                              </td>
                              <td style={{ width: "15%" }}>{"Site Collection Administrator"}</td>
                              <td style={{ width: "45%" }}>
                                <Link href={owner}>{owner}</Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-activity-content"}
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div
              style={{
                display: !props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />
              <div className="alert alert-light" role="alert" style={{ backgroundColor: "white" }}>
                <h4 className="alert-heading">No activity was found for this space</h4>
                <p></p>
                <hr />
                <p className="mb-0">
                  Collaboration Spaces created 24 hours ago or less might no have activity records
                  available, please wait 24-48 hours for the information to be available
                </p>
              </div>
            </div>
            <div
              style={{
                display: props.ownedResource.activityDataAvailable === false ? "none" : "grid",
              }}
            >
              <br />

              <table className="table table-bordered"  style={{ display: props.ownedResource.groupActivity === null ? "none" : "table" }} >
                <tbody>
                  <tr style={{ backgroundColor: "#0078d4", color: "white" }}>
                    <th>Group Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white" , textAlign: "justify"}}>
                  <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Member Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.groupActivity?.memberCount}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              External Member Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.groupActivity?.externalMemberCount}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Last Activity Date:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={ConvertDateStringToLongDate(
                                props.ownedResource.groupActivity?.lastActivityDate
                              )}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Yammer Posted Message Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                props.ownedResource.resourceType === "Yammer"
                                  ? props.ownedResource.groupActivity?.yammerPostedMessage
                                  : "Not available"
                              }
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Yammer Liked Msg Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                props.ownedResource.resourceType === "Yammer"
                                  ? props.ownedResource.groupActivity?.yammerLikedMessageCount
                                  : "Not available"
                              }
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Report Period:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={"90 Days"}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              File Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.groupActivity?.sharePointTotalFileCount}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Active File Count
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                props.ownedResource.groupActivity?.sharePointActiveFileCount === ""
                                  ? "0"
                                  : props.ownedResource.groupActivity?.sharePointActiveFileCount
                              }
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Allocated Storage:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value="25TB"
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Storage Used:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={
                                ConvertBytesToMB(
                                  props.ownedResource.groupActivity
                                    ?.sharePointSiteStorageByte as string
                                ) + "MB"
                              }
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered" style={{ display: props.ownedResource.teamsActivity === null ? "none" : "table" }} >
                <tbody>
                  <tr style={{ backgroundColor: "#5b5fc7", color: "white" }}>
                    <th>Teams Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white" , textAlign: "justify"}}>
                  <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Team Id:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.teamID}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Team Name:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.teamName}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Team Type:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.teamType}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Is Deleted:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.isDeleted}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Active Users:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.activeUsers}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Active Channels:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.activeChannels}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Channel Messages:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.channelMessages}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Reactions:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.reactions}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Meetings Organized:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.meetingsOrganized}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Guests:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.teamsActivity?.guests}
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered" style={{ display: props.ownedResource.yammerActivity === null ? "none" : "table" }} >
                <tbody>
                  <tr style={{ backgroundColor: "#0078d4", color: "white" }}>
                    <th>Viva Engage Activity Summary</th>
                  </tr>
                  <tr style={{ backgroundColor: "white" , textAlign: "justify"}}>
                  <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Member Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.yammerActivity?.memberCount}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Posted Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.yammerActivity?.postedCount}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Read Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.yammerActivity?.readCount}
                            />
                          </div>
                          <div className="col-2 col-lg-1">
                            <label
                              style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                float: left,
                                inlineSize: "100%",
                                textAlign: "start",
                                paddingLeft: "4%",
                              }}
                              className="col-sm-2 col-form-label"
                            >
                              Liked Count:
                            </label>
                          </div>
                          <div className="col-4 col-lg-5">
                            <input
                              style={{ display: "inline-block" }}
                              type="text"
                              className="form-control"
                              disabled
                              value={props.ownedResource.yammerActivity?.likedCount}
                            />
                          </div>
                        </div>
                        <br />
                      </div>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </div>
          <div
            className="tab-pane"
            id={props.baseTabId + "-compliance-content"}
            role="tabpanel"
            aria-labelledby="compliance-tab"
          >
            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <br />
              <table
                className="table"
                style={{
                  backgroundColor: "white",
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">Compliance Rule</th>
                    <th scope="col">Rule Description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ownership Enforcement</td>
                    <td>All workspaces must have at least 2 owners</td>
                    <td>
                      <ComplianceField
                        complianceResults={props.ownedResource.compliance.filter(
                          (compResult) =>
                            compResult.complianceRule === ComplianceRule.TwoOwnerEnforcement
                        )}
                      ></ComplianceField>
                    </td>
                    <td>
                      <TooltipHost
                        content="Add missing owner. Please keep in mind that in order to perform this operation the lease on your workspace must be up to date (not expired)."
                        calloutProps={calloutProps}
                        styles={hostStyles}
                      >
                        <button
                          className="btn btn-warning"
                          onClick={
                            props.ownedResource.agsTracked
                              ? toggleAGSHideDialog
                              : toggleOwnerSelectionHideDialog
                          }
                          disabled={twoOwnerEnformentCompliant}
                        >
                          Remediate
                        </button>
                      </TooltipHost>
                    </td>
                  </tr>
                  <tr>
                    <td>Lease Expiration</td>
                    <td>
                      All workspaces have an expiration date, you must renew your workspace's lease
                      regularly
                    </td>
                    <td>
                      <ComplianceField
                        complianceResults={props.ownedResource.compliance.filter(
                          (compResult) =>
                            compResult.complianceRule === ComplianceRule.LeaseExpiration
                        )}
                      ></ComplianceField>
                    </td>
                    <td>
                      <TooltipHost
                        content={
                          remainingDays > 180
                            ? "Your workspace still has six months or more of available lease time"
                            : "Extend the lease for this workspace by 6 months, your workspace must have at least two owners  "
                        }
                        calloutProps={calloutProps}
                        styles={hostStyles}
                      >
                        <button
                          className="btn btn-warning"
                          onClick={async () => {
                            await validateExternalResourceLease();
                          }}
                          disabled={shouldDisableLaseExtend(
                            twoOwnerEnformentCompliant,
                            remainingDays
                          )}
                        >
                          Extend Lease
                        </button>
                      </TooltipHost>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceDetailM365;
