interface ErrorDetails {
  errorTitle: string;
  errorSubtitle: string;
  errorType: string;
}

interface Errors {
  pageNotFound: ErrorDetails;
  notFoundError: ErrorDetails;
  somethingWentWrong: ErrorDetails;
  internalServerError: ErrorDetails;
  serviceUnavailable: ErrorDetails;
  badRequest: ErrorDetails;
  accessDenied: ErrorDetails;
  networkConnectionLost: ErrorDetails;
  authenticationFailed: ErrorDetails;
  accountSuspended: ErrorDetails;
  invalidUsernameOrPassword: ErrorDetails;
  fileNotFound: ErrorDetails;
  sessionExpired: ErrorDetails;
  paymentProcessingError: ErrorDetails;
  databaseConnectionError: ErrorDetails;
  maintenanceMode: ErrorDetails;
}

export const errorsTypes: Errors = {
  pageNotFound: {
    errorTitle: "Page Not Found",
    errorSubtitle: "We couldn't find the page you're looking for. Please check the URL for any errors, or use the navigation menu to find the desired content. If you believe this is a mistake, please contact our support team.",
    errorType: "pageNotFound",
  },
  notFoundError: {
    errorTitle: "404 Error",
    errorSubtitle: "Oops! The page you tried to access is not available. This could be due to a broken link or a page that has been moved or deleted. You can go back to the previous page or visit our homepage to explore more. If you think there's an issue, please let us know.",
    errorType: "notFoundError",
  },
  somethingWentWrong: {
    errorTitle: "Something Went Wrong",
    errorSubtitle: "An unexpected error occurred while processing your request. Our development team has been notified, and we are working to resolve the issue as quickly as possible.",
    errorType: "somethingWentWrong",
  },
  internalServerError: {
    errorTitle: "Internal Server Error",
    errorSubtitle: "Sorry, we're experiencing technical difficulties at the moment. This might be a temporary glitch in our system. Our team has been alerted and is investigating the root cause. We appreciate your patience, and please try again later. If the problem persists, please let us know.",
    errorType: "internalServerError",
  },
  serviceUnavailable: {
    errorTitle: "Service Unavailable",
    errorSubtitle: "The service you are trying to access is temporarily unavailable due to maintenance or high traffic. We apologize for any inconvenience. Please wait a few minutes and try again. If the issue persists, please check our status page or contact support for updates.",
    errorType: "serviceUnavailable",
  },
  badRequest: {
    errorTitle: "Bad Request",
    errorSubtitle: "The request sent to the server was invalid or improperly formatted. Please review the input data or parameters and try again. If you are unsure about how to proceed, our support team will be happy to assist you.",
    errorType: "badRequest",
  },
  accessDenied: {
    errorTitle: "Access Denied",
    errorSubtitle: "You don't have permission to view the requested content. If you believe you should have access, please ensure you are logged in with the correct account. If the problem persists, contact the site administrator for assistance.",
    errorType: "accessDenied",
  },
  networkConnectionLost: {
    errorTitle: "Network Connection Lost",
    errorSubtitle: "It seems like your internet connection is not stable or has been interrupted. Please check your network connection and try again. If you continue to experience issues, consider resetting your connection or contacting your internet service provider.",
    errorType: "networkConnectionLost",
  },
  authenticationFailed: {
    errorTitle: "Authentication Failed",
    errorSubtitle: "We were unable to verify your login credentials. Please double-check your username and password. If you've forgotten your password, you can reset it by following the \"Forgot Password\" link. If you need further assistance, please contact support.",
    errorType: "authenticationFailed",
  },
  accountSuspended: {
    errorTitle: "Account Suspended",
    errorSubtitle: "Your account has been temporarily suspended due to a violation of our terms of service. To resolve this issue, please reach out to our support team to discuss the suspension and any necessary steps for reinstatement.",
    errorType: "accountSuspended",
  },
  invalidUsernameOrPassword: {
    errorTitle: "Invalid Username or Password",
    errorSubtitle: "The username or password you entered is incorrect. Please verify your credentials and try again. If you continue to experience difficulties, consider resetting your password or contacting support for assistance.",
    errorType: "invalidUsernameOrPassword",
  },
  fileNotFound: {
    errorTitle: "File Not Found",
    errorSubtitle: "The file you are looking for could not be located. Please ensure you have the correct file name and location. If you believe this is an error, please contact support and provide specific details about the file you are trying to access.",
    errorType: "fileNotFound",
  },
  sessionExpired: {
    errorTitle: "Session Expired",
    errorSubtitle: "Your session has expired due to inactivity. To continue, please log in again. If you were in the middle of a task, your progress may not have been saved. If you encounter any issues, please let us know.",
    errorType: "sessionExpired",
  },
  paymentProcessingError: {
    errorTitle: "Payment Processing Error",
    errorSubtitle: "We encountered an issue while processing your payment. Please check your payment details and try again. If the problem persists, consider using an alternative payment method or contacting your payment provider.",
    errorType: "paymentProcessingError",
  },
  databaseConnectionError: {
    errorTitle: "Database Connection Error",
    errorSubtitle: "We are currently unable to establish a connection to the database. Our technical team has been notified, and they are actively working to restore the database. We apologize for the inconvenience and appreciate your understanding. Please try again later.",
    errorType: "databaseConnectionError",
  },
  maintenanceMode: {
    errorTitle: "Maintenance Mode",
    errorSubtitle: "The website is currently undergoing scheduled maintenance to provide you with an improved experience. We apologize for any inconvenience caused during this period. Please check back shortly, and everything should be up and running smoothly. Thank you for your patience.",
    errorType: "maintenanceMode",
  },
};