export  class TeamsActivity {
    teamID?: string = "";
    teamName?: string= "";
    lastActivityDate?: string = "";
    lastActivityDateFormatted?: Date;   
    teamType?: string= "";
    isDeleted?: string= "";
    activeUsers?: string= "";
    activeChannels?: string= "";
    channelMessages?: string= "";
    reactions?: string= "";
    meetingsOrganized?: string= "";
    postMessages?: string= "";
    replyMessages?: string= "";
    urgentMessages?: string= "";
    mentions?: string= "";
    guests?: string= "";
    activeSharedChannels?: string= "";
    activeExternalUsers?: string= "";
}