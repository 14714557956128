import {
  DirectionalHint,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import ResourceDetailM365 from "./resource-detailm365";
import ResourceDetailSPO from "./resource-detailspo";
import {
  checkResourceCompliance,
  drawTextInCanvas,
  generateRandomString,
  getFormattedDate,
  getResourceLink
} from "../utils";
import { resource } from "../objects/resource";
import { createRoot } from 'react-dom/client';
import ResourceNavlink from "./resource-navlink";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { apiScopes, msalConfig} from "../authConfig";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { ComplianceField } from "./compliance-field";
import { ChangeType } from "../enums";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import { deletem365Group, deleteSPOSite } from "../services/resource-manager";
import { errorsTypes } from "../errorTypes";
import StatusCode from "status-code-enum";
import { toast } from "react-toastify";
import { ExpirationDateField } from "./date-field";

interface ownedResources {
  ownedResources: Array<resource>;
  resourceType: string;
  refreshDatasetFunction: (resourceID: string, changeType: ChangeType, value: any) => void;
}

/**
 * Grid component, displays the list of resources on an accordion table
 */
export function ResourceGrid(props: ownedResources) {
  const { instance, accounts } = useMsal();
  const accessTokenRequest = {
    scopes: apiScopes.resourceManagementScopes,
    account: accounts[0],
  };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };
  function getDeleteButtonTooltip(isAGSTracked: boolean): ITooltipProps {
    const tooltipProps: ITooltipProps = {
      onRenderContent: () => (
        <div>
          <p>
            Before proceeding, please be aware that deleting a site or group in Office 365 has
            significant implications. This action is irreversible and will result in the permanent
            removal of all associated data, files, conversations, and settings.
          </p>
          <ul style={{ margin: 10, padding: 0 }}>
            <li>
              <strong>Data Loss: </strong>All files and information will be permanently deleted.
            </li>
            <li>
              <strong>Collaboration Impact: </strong>Ongoing collaborations and shared resources
              will be disrupted.
            </li>
            <li>
              <strong>Access Termination: </strong>All members will lose access immediately.
            </li>
            <li>
              <strong>Configuration Loss: </strong>Custom settings and permissions will be gone.
            </li>
          </ul>
        </div>
      ),
    };
    return tooltipProps;
  }
  async function redirectoDeletePage(currentResource: resource, currentIndex: number) {
    let confirmationCode = generateRandomString(6);
    let htmlDialogContent = (
      <div>
        <p>
          You are about to delete the '{currentResource.displayName}' site. This will delete all
          SharePoint content in the site. Please backup any files or other content before
          proceeding. Do you want to continue?
        </p>
        <br></br>
        <div className="container card">
          <canvas id="secretCodeCanvas" width="auto" height="100"></canvas>
        </div>
      </div>
    );

    const { value: paramConfCode } = await Swal.fire({
      title: "Delete this resource",
      input: "text",
      inputLabel: "Enter the text displayed above:",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      allowEnterKey: false,
      allowOutsideClick: false,
      inputValidator: (value) => {
        if (!value) return "Please enter the confirmation code";
        else if (value !== confirmationCode) return "The confirmation code is incorrect";
        else return null;
      },
      confirmButtonText: "Delete",
      confirmButtonColor: "#CE0000",
      inputPlaceholder: "Enter the code here",
      html: renderToStaticMarkup(htmlDialogContent),
      didOpen: () => {
        drawTextInCanvas("secretCodeCanvas", confirmationCode);
      },
    });
    if (paramConfCode) {
      if (paramConfCode === confirmationCode) {
        const toastDeleteId = toast.loading(`Deleting the resouce: ${currentResource.displayName} , this might take 30 seconds or more`);
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then(async (accessTokenResponse) => {
            let deleteResult: number = 0;

            props.refreshDatasetFunction(
              currentResource.isGroupConnected
                ? currentResource.azureAdId
                : currentResource.siteUrl,
              currentResource.isGroupConnected
                ? ChangeType.DeleteGroup
                : ChangeType.DeleteSite,
              null
            );
            try {
              let collapseButtonId = "btn-expandResource" + props.resourceType + currentIndex;
              let buttonElement = document.getElementById(collapseButtonId);
              let isTabExpanded = buttonElement?.getAttribute("aria-expanded");
              if(isTabExpanded === "true"){
                buttonElement?.click();
              }
            } catch {
              console.log("Could not trigger tab collapse");
            }

            if (currentResource.isGroupConnected) {
              deleteResult = await deletem365Group(
                accessTokenResponse.accessToken,
                currentResource.azureAdId
              );
            } else {
              deleteResult = await deleteSPOSite(
                accessTokenResponse.accessToken,
                currentResource.siteUrl
              );
            }

            if (deleteResult === StatusCode.SuccessNoContent) {
              toast.update(toastDeleteId, { render: `Resource succesfully deleted: ${currentResource.displayName}`, type: "success", isLoading: false, autoClose: 8000});
            } else {
              toast.update(toastDeleteId, { render: errorsTypes.internalServerError.errorSubtitle, type: "error", isLoading: false, autoClose: 8000 });
            }
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              toast.update(toastDeleteId, { render: `We encountered a problem while deleting: ${currentResource.displayName}. Please try again later or contact support. No data was deleted.`, type: "error", isLoading: false, autoClose: 8000 });
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      }
    }
  }

  function handleResourceDetailLoad(
    buttonElementID: string,
    containerID: string,
    currentResource: resource,
    currentArrayIndex: number
  ) {
    props.ownedResources.forEach((element) => {
      element.compliance = checkResourceCompliance(element);
    });
    const currentResourceDetailButton: HTMLElement | null =
      document.getElementById(buttonElementID);
    const msalInstance = new PublicClientApplication(msalConfig);
    if (!currentResourceDetailButton!.classList.contains("collapsed")) {
      const currentResourceDetailNode = document.getElementById(containerID)!;
      const root = createRoot(currentResourceDetailNode);
      if (currentResource.resourceType === "SPO") {
        root.render(
          <MsalProvider instance={msalInstance}>
            <ResourceDetailSPO
              ownedResource={currentResource}
              baseTabId={"tab-" + props.resourceType + currentArrayIndex}
              refreshDatasetFunction={props.refreshDatasetFunction}
            />
          </MsalProvider>
        );
      } else {
        root.render(
          <MsalProvider instance={msalInstance}>
            <ResourceDetailM365
              ownedResource={currentResource}
              baseTabId={"tab-" + props.resourceType + currentArrayIndex}
              refreshDatasetFunction={props.refreshDatasetFunction}
            />
          </MsalProvider>
        );
      }
    }
  }

  return (
    <div>
      <div
        style={{
          display: props.ownedResources.length === 0 ? "inline" : "none",
        }}
      >
        <div className="alert alert-light" role="alert">
          <h4 className="alert-heading">No resources to show</h4>
          <p></p>
          <hr />
          <p className="mb-0">
            You don't own any resource of this type at the moment. However, you have the option to
            use the{" "}
            <a
              target="_blank"
              href="https://apps.powerapps.com/play/e/c57452c8-1d59-4cc7-bb06-dd1bd68f06bf/a/44b067d3-ed7a-4b63-8933-c48670ed9a14?tenantId=46c98d88-e344-4ed4-8496-4ed7712e255d"
              rel="noreferrer"
            >
              setup tool
            </a>{" "}
            to create a new collaboration space.
          </p>
        </div>
      </div>
      <table
        className="accordion table table-responsive"
        style={{
          display: props.ownedResources.length > 0 ? "inline-table" : "none",
          width: "100%",
        }}
      >
        <thead>
          <tr style={{textAlign:"justify"}}>
            <th scope="col"></th>
            <th scope="col">Workspace Name</th>
            <th scope="col">Sensitivity Label</th>
            <th scope="col">Workspace Type</th>
            <th scope="col">Status</th>
            <th scope="col">Expiration Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {props.ownedResources?.map((resource, i) => {
          return (
            <tbody
              id={"tbl-resource" + props.resourceType + i}
              key={"tbl-resource" + props.resourceType + i}
            >
              <tr style={{ textAlign: "justify" }}>
                <td>
                  <button
                    id={"btn-expandResource" + props.resourceType + i}
                    title="Display additional information about this resource"
                    className="accordion-button accordion-button-plusminus collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#resource-row" + props.resourceType + i}
                    data-resourceid={
                      resource.isGroupConnected ? resource.azureAdId : resource.siteId
                    }
                    data-isconnected={resource.isGroupConnected ? true : false}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(targetButton) =>
                      handleResourceDetailLoad(
                        targetButton.currentTarget.id,
                        "resourceDetailContainer-" + props.resourceType + i,
                        resource,
                        i
                      )
                    }
                  ></button>
                </td>
                <td>
                  <div style={{ paddingTop: "3px", margin: "auto", textAlign: "justify" }}>
                    {resource.displayName !== "" ? resource.displayName : resource.siteUrl}
                  </div>
                </td>
                <td>
                  <div style={{ paddingTop: "3px" }}>
                    <TooltipHost content="Sensitivity labels let you classify and protect your organization's data, while making sure that user productivity and their ability to collaborate isn't hindered.">
                      {resource.sensitivityLabel === "" ? "Not Set" : resource.sensitivityLabel}
                    </TooltipHost>
                  </div>
                </td>
                <td>
                  <ResourceNavlink
                    resourceId={resource.azureAdId}
                    resourceType={resource.resourceType}
                    resourceUrl={getResourceLink(resource)}
                  />
                </td>
                <td>
                  <ComplianceField complianceResults={resource.compliance}></ComplianceField>
                </td>
                <td>
                  <ExpirationDateField
                    lastActivityDate={resource.lastUpdatedFormatted}
                    complianceResults={resource.compliance}
                    formatedComplianceDate={getFormattedDate(resource.renewDateFormatted, false)}
                    sensitivityLabel={resource.sensitivityLabel}
                  />
                </td>
                <td>
                  <TooltipHost
                    tooltipProps={getDeleteButtonTooltip(resource.agsTracked)}
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.bottomCenter}
                    styles={hostStyles}
                  >
                    <button
                      type="button"
                      className="btn btn-danger me-1"
                      onClick={async () => {
                        await redirectoDeletePage(resource, i);
                      }}
                    >
                      Delete
                    </button>
                  </TooltipHost>
                </td>
              </tr>
              <tr data-bs-parent=".table" style={{ borderColor: "transparent" }}>
                <td colSpan={8} style={{ padding: "0px" }}>
                  <div
                    id={"resource-row" + props.resourceType + i}
                    className="collapse"
                    data-bs-parent=".table"
                  >
                    <div className="accordion-body" style={{ padding: "0" }}>
                      <br />
                      <div id={"resourceDetailContainer-" + props.resourceType + i}></div>
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
}
