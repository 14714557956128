import { ResourceGrid } from "../components/resources-grid";
import { resource } from "../objects/resource";
import { Text, ITextProps  } from "@fluentui/react";
import { ChangeType } from "../enums";
interface resourceListProps {
  ownedResources: Array<resource>;
  refreshDatasetFunction: (resourceID:string,changeType:ChangeType,value:any) => void;
}

export function ResourceGridByProduct(props: resourceListProps) {
  let siteList = props.ownedResources.filter((p) => p.resourceType === "SPO" || p.resourceType === "CSPO");
  let teamsList = props.ownedResources.filter(
    (p) => p.resourceType === "Teams"
  );
  let yammerList = props.ownedResources.filter(
    (p) => p.resourceType === "Yammer"
  );

  return (
    <div>
      <br/>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id="headingOne"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <Text
                variant={"xLarge" as ITextProps["variant"]}
                style={{
                  color: "#038387",
                  fontWeight: "bolder",
                  fontFamily:
                    '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                }}
              >
                SharePoint Online ({siteList.length})
              </Text>
            </button>
          </h2>
          <div
            id="collapseOne"
            className="panel multi-collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ResourceGrid ownedResources={siteList} resourceType="SPO" refreshDatasetFunction={props.refreshDatasetFunction}/>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id="headingTwo"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <Text
                variant={"xLarge" as ITextProps["variant"]}
                style={{
                  color: "#464775",
                  fontWeight: "bolder",
                  fontFamily:
                    '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                }}
              >
                Microsoft Teams ({teamsList.length})
              </Text>
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="panel multi-collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ResourceGrid ownedResources={teamsList} resourceType="Teams" refreshDatasetFunction={props.refreshDatasetFunction}/>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2
            className="accordion-header"
            id="headingThree"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <Text
                variant={"xLarge" as ITextProps["variant"]}
                style={{
                  color: "#135995",
                  fontWeight: "bolder",
                  fontFamily:
                    '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                }}
              >
                Viva Engage (Yammer) ({yammerList.length})
              </Text>
            </button>
          </h2>
          <div
            id="collapseThree"
            className="panel multi-collapse show"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <ResourceGrid ownedResources={yammerList} resourceType="Yammer" refreshDatasetFunction={props.refreshDatasetFunction}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceGridByProduct;
