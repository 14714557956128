import { resourceManagementAPIConfig } from "../authConfig";
import { SensitivityLabel } from "../enums";
import { activityLog } from "../objects/activityLog";
import { directoryObject } from "../objects/directoryObj";
import { M365GroupActivity } from "../objects/m365GroupActivity";
import { m365group } from "../objects/m365group";
import { sensitivityLabelChange } from "../objects/sensitivityLabelChange";
import { spoSiteOwnershipChange } from "../objects/spoOwnershipChange";
import { nonConnectedSite } from "../objects/spoSite";
import { SharePointActivity } from "../objects/spoSiteActivity";
import { team } from "../objects/team";



export async function getCurrentUserActivity(accessToken: string): Promise<Array<activityLog>> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseActivityURL}${resourceManagementAPIConfig.getMyActivity}`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getOwnedM365Groups(accessToken: string): Promise<Array<m365group>> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}


export async function getOwnedStandaloneSPOSites(accessToken: string): Promise<Array<nonConnectedSite>> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getSingleOwnedM365Group(accessToken: string, groupObjectId: string): Promise<m365group> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}/${groupObjectId}`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getSingleOwnedTeam(accessToken: string, groupObjectId: string): Promise<team> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}/${groupObjectId}/team`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getM365GroupOwners(accessToken: string, groupObjectId: string): Promise<Array<directoryObject>> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}/${groupObjectId}/owners`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getSPOSiteOwners(accessToken: string, siteUrl: string): Promise<Array<directoryObject>> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}/${siteUrl}/owners`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getM365GroupActivity(accessToken: string, groupObjectId: string): Promise<M365GroupActivity> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}/${groupObjectId}/activity`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function getSPOSiteActivity(accessToken: string, siteUrl: string): Promise<SharePointActivity> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "GET",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}/${siteUrl}/activity`, options)
        .then(response => response.json())
        .catch(error => {throw error;});
}

export async function addSPOSiteOwner(accessToken: string, siteUrl: string, email: string): Promise<number> {
    let ownershipObj = new spoSiteOwnershipChange();
    ownershipObj.siteUrl = siteUrl;
    ownershipObj.newOwnerEmail = email;
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "PUT",
        headers: headers,
        mode : "cors",
        body: JSON.stringify(ownershipObj)
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}/addowner`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}

export async function addM365GroupOwner(accessToken: string, groupId: string, userId: string): Promise<number> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "PUT",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}/${groupId}/owners/${userId}`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}

export async function extendResourceLease(accessToken: string, resourceIdentifier: string, isM365Connected: boolean): Promise<number> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "POST",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}/resources/extendlease?resourceIdentifier=${resourceIdentifier}&isM365Connected=${isM365Connected}`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}

export async function updateSensitivityLabel(accessToken: string, resourceIdentifier: string, isM365Connected: boolean,newSensitivityLabel:SensitivityLabel): Promise<number> {
    let newChangeObj = new sensitivityLabelChange();
    newChangeObj.resourceIdentifier = resourceIdentifier;
    newChangeObj.isM365Connected =  isM365Connected;
    newChangeObj.newSensitivityLabel = newSensitivityLabel as string;
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "POST",
        headers: headers,
        mode : "cors",
        body: JSON.stringify(newChangeObj)
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}/resources/updatesensitivitylabel`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}

export async function deletem365Group(accessToken: string, groupId: string): Promise<number> {
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "DELETE",
        headers: headers,
        mode : "cors"
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getM365Spaces}/${groupId}`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}

export async function deleteSPOSite(accessToken: string, siteUrl: string): Promise<number> {

    let spoSiteObj = { siteUrl , Id : ""}
    const headers = new Headers({});
    headers.set('Content-Type', 'application/json');
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options : RequestInit= {
        method: "POST",
        headers: headers,
        mode : "cors",
        body: JSON.stringify(spoSiteObj)
    };

    return fetch(`${resourceManagementAPIConfig.baseURL}${resourceManagementAPIConfig.getSPOSpaces}/delete`, options)
        .then(response => response.status)
        .catch(error => {throw error;});
}