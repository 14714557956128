import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import "@intc/dlux-bootstrap/dist/css/dlux.css";
import "@popperjs/core/dist/cjs/popper.js"
import "bootstrap/dist/js/bootstrap.min.js"
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "./css_fabric48.css"
import "@sweetalert2/themes/bootstrap-4/bootstrap-4.css"
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
    <App />
    </MsalAuthenticationTemplate>
    </MsalProvider>
    <ToastContainer />
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
