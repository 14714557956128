import {
  DirectionalHint,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
  Text
} from "@fluentui/react";
import "../index.css";
import { ReactComponent as SPOlogo} from '../msicons/Sharepoint.svg';
import { ReactComponent as TeamsLogo} from '../msicons/Teams.svg';
import { ReactComponent as YammerLogo} from '../msicons/viva.svg';
interface ResourceNavlinkProps {
  resourceType: string;
  resourceId: string;
  resourceUrl: string;
}

/**
 * User manager component, can be used to manage owners or members
 */
export function ResourceNavlink(props: ResourceNavlinkProps) {
  let productTypeName: string;
  let navlinkColor: string;
  let productDescription: string;

  function navigateToResource() {
      window.open(props.resourceUrl, "_blank")?.focus();
  }

  function getResourceIcon(resourceType: string): JSX.Element {
    if (resourceType === "SPO") {
      return <SPOlogo style={{width:"10%",height:"10%"}}/>
    }
    if (resourceType === "Teams") {
      return <TeamsLogo/>;
    }
    if (resourceType === "Yammer") {
      return <YammerLogo style={{width:"10%",height:"10%"}}></YammerLogo>;
    } else {
      return <SPOlogo style={{width:"10%",height:"10%"}}/>
    }
  }
  if (props.resourceType === "SPO") {
    productTypeName = "SharePoint Online Site";
    navlinkColor = "#077568";
    productDescription =
      "SharePoint Online is a cloud-based collaboration and document management system within Microsoft 365. It facilitates creating, storing, organizing, and sharing content, enabling teams to collaborate on files, documents, and information in a secure and centralized online environment.";
  } else if (props.resourceType === "Teams") {
    productTypeName = "Microsoft Teams";
    navlinkColor = "#6264a7";
    productDescription =
      "Microsoft Teams is a collaboration and communication hub in Microsoft 365. It combines chat, video meetings, file storage, and app integration, enabling teams to work together effectively, whether they are in the same office or distributed across different locations.";
  } else if (props.resourceType === "Yammer") {
    productTypeName = "Viva Engage Community";
    navlinkColor = "#106ebe";
    productDescription =
      "Viva Engage is a Microsoft 365 product that serves as an enterprise social networking platform. It allows employees within an organization to connect, collaborate, and share ideas, information, and updates in a secure and private online space.";
  } else {
    productTypeName = "SharePoint Online Site";
    navlinkColor = "#077568";
    productDescription =
      "SharePoint Online is a cloud-based collaboration and document management system within Microsoft 365. It facilitates creating, storing, organizing, and sharing content, enabling teams to collaborate on files, documents, and information in a secure and centralized online environment.";
  }
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <div style={{ margin: 10 }}>
        <Text variant="mediumPlus" block style={{ fontWeight: "bold" }}>
          Click this button to navigate to this space in a new tab
        </Text>
        <br></br>
        <Text
          variant="medium"
          block
          style={{ fontWeight: "bold", color: navlinkColor }}
        >
          {productDescription}
        </Text>
      </div>
    ),
  };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.bottomAutoEdge}
      styles={hostStyles}
    >
      <div style={{display:"inline-block",overflow:"hidden",whiteSpace:"nowrap"}} onClick={()=>{navigateToResource()}}>{getResourceIcon(props.resourceType)}
      <button className="btn shadow-none" style={{fontSize:"14px",overflow:"hidden"}}>{productTypeName}  <i className="intelicon-external-link" style={{overflow:"hidden"}}></i></button>
      </div>
    </TooltipHost>
  );
}

export default ResourceNavlink;
